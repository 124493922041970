<template>
  <div class="companyProfile">
    <div class="title-box">
      <h3>公司简介</h3>
      <!-- <p>新云网科技集团股份有限公司</p> -->
    </div>
    <div class="img-box">
      <img src="../../assets/images/website/about-1.png" class="img-box1">
    </div>
    <div class="remark">
      新云网科技集团股份有限公司创立于2015年，是一家深耕信息技术领域的高新技术企业，是国内领先的智慧教育产品研发、生产和整体解决方案提供商。
    </div>
    <div class="img-box">
      <img src="../../assets/images/website/about-2.png" class="img-box2">
    </div>
    <div class="remark">
      公司重点聚焦智慧教育原创科技，依托自身科技创新能力，坚持自主研发，运用云计算、互联网、人工智能、大数据等前沿技术，将信息技术与教育教学深度融合，研制出数字化校园平台、大数据服务平台、多场景物联网智能终端等在内的系列产品，形成独具特色的“一套系统创云校、一张讲台连万校、一台终端牵家校”的教育服务体系，构建了“端-边-云”架构的软硬件一体化智慧教育整体解决方案，用共享思维建设没有围墙的校园，助推数字教育的全面普及。
    </div>
    <div class="img-box">
      <img src="../../assets/images/website/about-3.png" class="img-box3">
    </div>
    <p class="remark">
      作为技术研发型企业，新云网坚持核心技术自主可控，不断将先进的信息技术应用于产品研发与创新，在教育信息化领域取得累累硕果。公司现有国内外专利、软著等各类知识产权1500余件，拥有“智慧教育湖南省重点实验室”、“湖南省企业技术中心”两个研发创新平台，是全国信息技术标准化委员会教育分会的单位委员。自主研制的系列产品先后荣获湖南省首届工业产品创新奖、高校创新创业创造教育精品成果特等奖等。产品在全国校园得到广泛应用，赢得学校、师生和家长的一致好评。
    </p>
    <div class="img-box">
      <img src="../../assets/images/website/about-4.png" class="img-box4">
    </div>
    <div class="remark">
      教育是立国之本、强国之基，在加快实现教育现代化和建设教育强国进程中，公司紧紧围绕“做时代经典，服务亿万民众”的企业愿景，秉承“至诚至信，精益求精”的经营理念，追求卓越，永不懈怠，矢志不渝坚持技术革新，助力实现教育高质量发展。
    </div>
    <div class="remark" style="margin-top:10px">
      新云网愿与更多合作伙伴一起携手同行，为客户提供更加优质的产品和高效的服务，让科技赋能教育，用智慧引领未来！
    </div>
    <div class="culture-box">
      <p class="title">
        企业文化
      </p>
      <div class="img-box">
        <img src="../../assets/images/website/about-5.png" class="img-box5">
      </div>
      <div class="our-box">
        <div class="line">
          <label>公司愿景：</label>
          <span>做时代经典 服务亿万民众</span>
        </div>
        <div class="line">
          <label>核心价值：</label>
          <span>追求卓越 永不懈怠</span>
        </div>
        <div class="line">
          <label>经营理念：</label>
          <span>至诚至信 精益求精</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  }
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .companyProfile{
    max-width: 677px;
    margin: 0 auto;
  }
}
.companyProfile{
    background: #fff;
    padding: 15px;
    .title-box{
        h3{
            font-size: 24px;
            font-weight: 800;
            color: #000000;
        }
        p{
            font-size: 16px;
            font-weight: bold;
            color: #5E6D93;
            letter-spacing: 1px;
            margin-top: 5px;
        }
    }
    .img-box{
        text-align: center;
        margin: 16px 0;
        .img-box1{
          width: 100%;
          height: 100%;
        }
        .img-box2{
          width: 100%;
          height: 100%;
        }
        .img-box3{
          width: 100%;
          height: 100%;
        }
        .img-box4{
          width: 100%;
          height: 100%;
        }
        .img-box5{
          width: 100%;
          height: 100%;
        }
    }
    .remark{
        font-size: 17px;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
        text-indent:40px;
    }
    .culture-box{
        padding-bottom: 30px;
        .title{
            font-size: 20px;
            font-weight: 800;
            color: #000000;
            text-align: center;
            margin-top: 30px;
        }
        .our-box{
            .line{
                color: #333333;
                font-size: 17px;
                line-height: 40px;
                label{
                    font-weight: 800;
                }
                span{
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
